import { Controller } from "@hotwired/stimulus"

import Velocity from 'velocity-animate'

export default class extends Controller {
  static targets = ['expandable', 'collapsible']
  static values = {
    expanded: Boolean,
    animation: String
  }

  connect() {
    if (this.expandedValue == null) this.expandedValue = false
    if (this.expandedValue == true) {
      this.expandableTarget.classList.remove('hidden')
      if (this.hasCollapsibleTarget) this.collapsibleTarget.classList.add('hidden')
    }
  }

  expand() {
    event.preventDefault()

    this.expandedValue = true
    this.expandableTarget.classList.remove('hidden')

    if (this.animationValue == 'slide') {
      Velocity(this.expandableTarget, 'slideDown')
    }
    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });

    if (this.hasCollapsibleTarget) {
      this.collapsibleTarget.classList.add('hidden')
      if (this.animationValue == 'slide') {
        Velocity(this.collapsibleTarget, 'slideUp')
      }
      this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });
    }
  }

  collapse() {
    event.preventDefault()

    this.expandedValue = false
    this.expandableTarget.classList.add('hidden')

    if (this.animationValue == 'slide') {
      Velocity(this.expandableTarget, 'slideUp')
    }
    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });

    if (this.hasCollapsibleTarget) {
      this.collapsibleTarget.classList.remove('hidden')
      if (this.animationValue == 'slide') {
        Velocity(this.collapsibleTarget, 'slideDown')
      }
      this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });
    }
  }

  toggle() {
    event.preventDefault()

    if (this.expandedValue == false) {
      this.expand()
    } else {
      this.collapse()
    }
  }
}
