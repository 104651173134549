// Require defaults
require("@rails/ujs").start()
import "@hotwired/turbo-rails"

// Font Awesome
import "../../../src/websites/default/fontawesome"

document.addEventListener('turbo:render', function() {
  if (typeof _takashi !== 'undefined') {
    _takashi.init_form();
  }
  _takashi_ga('takashi.send', 'pageview');
});

// Stimulus
import "../../../controllers/websites/default"
