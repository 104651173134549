import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sidebar', 'bg']

  connect() {
    this.bodyElement = document.body
  }

  open() {
    this.bodyElement.setAttribute("data-lock-scrolling", "")
    this.bgTarget.classList.remove("hidden")
    this.sidebarTarget.classList.add("is-transitioning")
    setTimeout(() => {
      this.sidebarTarget.classList.add("is-open")
    }, 10)
    this.sidebarTarget.addEventListener("transitionend", () => {
      this.sidebarTarget.classList.remove("is-transitioning")
    }, { once: true })
  }

  close() {
    if (this.sidebarTarget.classList.contains("is-open")) {
      this.bodyElement.removeAttribute("data-lock-scrolling")
      this.bgTarget.classList.add("hidden")
      this.sidebarTarget.classList.add("is-transitioning")
      this.sidebarTarget.classList.remove("is-open")
      this.sidebarTarget.addEventListener("transitionend", () => {
        this.sidebarTarget.classList.remove("is-transitioning")
      }, { once: true })
    }
  }
}
