import { Controller } from "@hotwired/stimulus"

import Velocity from 'velocity-animate'

// REQUIRES VELOCITY ANIMATE
export default class extends Controller {
  static targets = ['header', 'body']

  connect() {
  }

  toggle(event) {
    event.preventDefault()

    if (this.headerTarget.classList.contains("is-expanded")) {
      var slideDir = 'slideUp'
      this.headerTarget.classList.remove("is-expanded")
    } else {
      var slideDir = 'slideDown'
      this.headerTarget.classList.add("is-expanded")
    }
    Velocity(this.bodyTarget, slideDir, {duration: 400, easing: 'ease-in-out' })
  }
}
