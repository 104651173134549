import { Controller } from "@hotwired/stimulus"

import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = [ "button", "popover" ]

  connect() {
  }

  open() {
    event.preventDefault()

    var reference = this.buttonTarget
    var popover_id = reference.dataset.popoverId

    if (popover_id) {
      var popover = document.querySelector(`[data-popper-target~='popover'][data-id='${popover_id}']`)
    } else {
      var popover = this.popoverTarget
    }

    if (popover.style.display == 'block') {
      // Hide current popover
      // and do nothing else
      this.close()
    } else {
      // Hide current popover
      this.close()

      var arrow = document.createElement('div')
      arrow.setAttribute('data-popper-arrow', '')
      popover.prepend(arrow)

      // Show new popover
      var popper = createPopper(reference, popover, {
        placement: popover.dataset.placement || 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0,10]
            }
          },
          {
            name: 'flip',
            enabled: popover.dataset.flip == 'true' || false,
          }
        ]
      })

      popover.style.display = 'block'
      popper.update()

      // Set current popper targets
      if (popover_id) {
        var buttons = document.querySelectorAll(`[data-popper-target~='button'][data-popover-id='${popover_id}']`)
      } else {
        var buttons = [reference]
      }
      buttons.forEach((el) => {
        el.classList.add('is-active')
        el.dataset.currentPopperTarget = 'button'
      })

      popover.dataset.currentPopperTarget = 'popover'
    }

  }

  close() {
    let currentPopperController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('body'),
      "current-popper"
    )
    currentPopperController.close()
  }
}
