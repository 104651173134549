// fixed_sidebar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "sidebar"]

  connect() {
    this.marginTop = 0
    this.top = 0
    this.setSidebarDimensions()

    // Bind the methods to preserve 'this' context
    this.boundSetDimensions = this.setSidebarDimensions.bind(this)
    this.boundHandleScroll = this.handleScroll.bind(this)

    // Add event listeners
    window.addEventListener('resize', this.boundSetDimensions)
    window.addEventListener('scroll', this.boundHandleScroll)
  }

  disconnect() {
    // Clean up event listeners
    window.removeEventListener('resize', this.boundSetDimensions)
    window.removeEventListener('scroll', this.boundHandleScroll)
  }

  setSidebarDimensions() {
    this.marginTop = parseFloat(getComputedStyle(this.wrapperTarget.closest('.section')).paddingTop)
    this.marginRight = parseFloat(getComputedStyle(this.wrapperTarget).paddingRight)

    this.sidebarTarget.style.width = `${this.wrapperTarget.offsetWidth - this.marginRight * 2}px`
    this.sidebarTarget.style.marginTop = `-${this.marginTop * 2}px`

    this.top = this.wrapperTarget.offsetTop - this.marginTop * 3
  }

  handleScroll() {
    const bottom = this.element.offsetTop +
                  this.element.offsetHeight -
                  this.sidebarTarget.offsetHeight -
                  (this.marginTop * 2)

    const y = window.scrollY

    if (y >= bottom) {
      this.sidebarTarget.classList.add('sidebar--bottom')
      this.sidebarTarget.classList.remove('sidebar--fixed')
      this.sidebarTarget.style.position = 'absolute'
      this.sidebarTarget.style.top = `${bottom - this.element.offsetTop + this.marginTop * 2}px`
    } else if (y >= this.top) {
      this.sidebarTarget.classList.add('sidebar--fixed')
      this.sidebarTarget.classList.remove('sidebar--bottom')
      this.sidebarTarget.style.position = ''
      this.sidebarTarget.style.top = `${this.marginTop * 3}px`
    } else {
      this.sidebarTarget.classList.remove('sidebar--fixed', 'sidebar--bottom')
      this.sidebarTarget.style.position = ''
      this.sidebarTarget.style.top = ''
    }
  }
}
