import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    coordinates: Array
  }

  connect() {
    this.panWidth = -(this.element.offsetWidth / 100) * 6
    this.initGoogleMaps()
  }

  initGoogleMaps() {
    console.log('init')
    if (typeof google !== "undefined") {
      this.setupMaps()
    } else {
      console.log('waiting for Google Maps to load')
      setTimeout(() => this.initGoogleMaps(), 500)
    }
  }

  setupMaps() {
    const mapElement = this.element
    const locations = this.coordinatesValue

    this.map = new google.maps.Map(mapElement, {
      zoom: 5,
      disableDefaultUI: true,
      scrollwheel: false,
      zoomControl: false,
      disableDoubleClickZoom: true,
      panControl: false,
      streetViewControl: false,
      draggable: false
    })

    const bounds = new google.maps.LatLngBounds()

    // Create markers for each location
    locations.forEach(location => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(location.lat, location.lng),
        map: this.map,
        url: location.url
      })

      // Extend bounds to include this marker
      bounds.extend(marker.position)

      // Add click listener to redirect to location page
      google.maps.event.addListener(marker, 'click', function() {
        window.location.href = this.url
      })
    })

    // Fit map to bounds
    this.map.fitBounds(bounds)

    // Pan map if on large screen
    if (window.matchMedia('(min-width: 1024px)').matches) {
      this.map.panBy(this.panWidth, 0)
    }

    // Keep map centered on window resize
    google.maps.event.addDomListener(this.map, 'idle', () => {
      this.center = this.map.getCenter()
    })

    google.maps.event.addDomListener(window, 'resize', () => {
      this.map.setCenter(this.center)
    })
  }
}
