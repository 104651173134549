import { Controller } from "@hotwired/stimulus"

import Lightbox from '@websites/photoswipe-v5.4.4/lightbox';

export default class extends Controller {
  static targets = []

  connect() {
    var gallerySelector = '[data-controller~=gallery]'
    var slideSelector = '.attachment--preview'

    this.element.querySelectorAll(slideSelector).forEach((slideEl, index) => {
      let aEl = slideEl.querySelector('a')
      let imageEl = slideEl.querySelector('img')
      let size = [imageEl.naturalWidth, imageEl.naturalHeight]
      aEl.dataset.pswpWidth = size[0]
      aEl.dataset.pswpHeight = size[1]
    })

    const lightbox = new Lightbox({
      gallery: gallerySelector,
      children: slideSelector,
      pswpModule: () => import('@websites/photoswipe-v5.4.4')
    });
    lightbox.init();
  }
}
